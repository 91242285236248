<template>
  <!--begin::Customer Listing-->
  <div class="warranty-template">
    <!-- {{ dataLoading }} -->
    <PageHeaderCount
      moduleType="warranty"
      :dataLoading="dataLoading"
      :statusList="statusList"
      allkey="all_warranty"
      countkey="warranty_status_count"
    ></PageHeaderCount>
    <!--  <v-col
      v-if="alphabets.length"
      md="12"
      class="alphabets-filter d-flex justify-center"
    >
      <div class="alphabets-scrollable" v-if="false">
        <v-btn
          depressed
          v-on:click="getLineItem(null, 'alphabet')"
          :class="{
            'cyan--text': null == alphabet,
            'cyan white--text': null != alphabet,
          }"
          class="custom-bold-button"
          small
          >All</v-btn
        >
        <v-btn
          depressed
          v-for="(row, index) in alphabets"
          v-on:click="getLineItem(row, 'alphabet')"
          :class="{
            'cyan--text': row == alphabet,
            'cyan white--text': row != alphabet,
          }"
          class="custom-bold-button"
          small
          :key="index"
          >{{ row }}
          <v-badge
            bordered
            color="error"
            class="custom-margin"
            overlap
            dot
            offset-x="10"
            offset-y="10"
          >
          </v-badge>
        </v-btn>
      </div>
    </v-col> -->
    <ListingTemplate
      :customClass="'warranty-listing'"
      v-if="getPermission('warranty:view')"
    >
      <template v-slot:body>
        <ListingHeader>
          <template v-slot:content>
            <template v-if="selectedRows.length > 0">
              <v-flex class="pt-0 mxw-200 my-auto">
                <h1 class="form-title d-flex margin-auto selected-rows-text">
                  {{ totalSelected }} Selected
                </h1>
              </v-flex>
              <v-flex class="pt-0 justify-flex-end d-flex margin-auto">
                <template v-if="getPermission('warranty:update')">
                  <v-menu
                    content-class="custom-menu-list"
                    bottom
                    offset-y
                    transition="slide-y-transition"
                    :close-on-click="true"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="dataLoading"
                        class="mx-2 custom-grey-border custom-bold-button"
                        color="cyan white--text"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark left>mdi-chevron-down</v-icon>Bulk Action
                      </v-btn>
                    </template>
                    <v-list>
                      <template v-for="(more, index) in customerMoreAction">
                        <v-list-item
                          link
                          v-on:click="updateMoreAction(more.action)"
                          :key="index"
                        >
                          <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                            <v-icon class="icon-default-blue">{{
                              more.icon
                            }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title
                            class="font-weight-500 font-size-14"
                            >{{ more.title }}</v-list-item-title
                          >
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu>
                </template>
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="red lighten-1 white--text"
                  v-on:click="clearSelections"
                >
                  <v-icon dark left>mdi-close</v-icon>Clear Selections
                </v-btn>
              </v-flex>
            </template>
            <template v-else>
              <v-flex class="pt-0 mxw-200">
                <h1 class="form-title d-flex margin-auto">
                  <v-select
                    :disabled="dataLoading"
                    :items="statusList"
                    v-model="status"
                    hide-details
                    item-color="cyan"
                    class="pt-0 mt-0 listing-status main-listing-status-filter"
                    item-text="text"
                    item-value="value"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip
                          :color="item.color"
                          :text-color="item.textcolor"
                        >
                          <template v-if="item.value == 'all'">{{
                            item.all_warranty
                          }}</template>
                          <template v-else>{{
                            item.warranty_status_count
                          }}</template>
                        </v-chip>
                      </v-list-item-action>
                    </template>
                  </v-select>
                </h1>
              </v-flex>

              <v-spacer></v-spacer>
              <v-flex
                class="pt-0 justify-flex-end d-flex margin-auto all-project-btn-right"
              >
                <!-- <v-autocomplete
                  hide-details
                  v-model.trim="filter_type"
                  :items="customerType"
                  clearable
                  style="max-width: 250px !important"
                  dense
                  flat
                  filled
                  label="Customer type"
                  item-color="cyan"
                  color="cyan"
                  solo
                  :disabled="dataLoading"
                  :loading="dataLoading"
                  v-on:change="getRows()"
                  item-value="value"
                  item-text="text"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title
                        v-html="'No Status(s) Found.'"
                      ></v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <v-list-item-action class="ma-0">
                      <v-chip
                        style="height: 10px; width: 10px; padding: 0"
                        :color="item.color"
                      >
                      </v-chip>
                    </v-list-item-action>
                    <v-list-item-content class="py-0">
                      <v-list-item-title
                        class="text-capitalize font-weight-500 font-size-16"
                      >
                        <template v-if="item.value == 'all'"
                          >{{ item.text }}
                        </template>
                        <template v-else>{{ item.text }} </template>
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-action class="mr-0">
                      <v-chip
                        style="height: 16px; width: 16px; padding: 0"
                        :color="item.color"
                      >
                      </v-chip>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-capitalize font-weight-500 font-size-16"
                        >{{ item.text }}</v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-action class="align-self-center">
                      <v-list-item-subtitle
                        class="text-lowercase font-weight-500 font-size-14"
                      >
                        <template v-if="item.value == 'all'">{{
                          item.all_ticket_visits
                        }}</template>
                        <template v-else>{{
                          item.ticket_visit_status_count
                        }}</template></v-list-item-subtitle
                      >
                    </v-list-item-action>
                  </template>
                </v-autocomplete> -->

                <!--    <v-text-field
                  dense
                  flat
                  filled
                  color="cyan"
                  solo
                  v-model="serial_no"
                  hide-details
                  label="Serial no."
                  class="ml-1"
                  style="max-width: 200px !important"
                ></v-text-field> -->
                <DateRangePicker
                  class="ml-1"
                  v-model="dates"
                  v-on:change="getRows"
                  prop_label="Filter Date Range"
                >
                </DateRangePicker>
                <!-- <v-text-field
                  dense
                  flat
                  filled
                  color="cyan"
                  solo
                  v-model="search"
                  hide-details
                  label="Search"
                  prepend-inner-icon="mdi-magnify"
                  style="max-width: 200px !important"
                  class="ml-1"
                ></v-text-field> -->
                <template v-if="getPermission('customer:create')">
                  <v-btn
                    depressed
                    :disabled="dataLoading"
                    v-on:click="addWarrantyDialog = true"
                    class="mx-2 custom-bold-button custom-btn-color white--text"
                  >
                    <v-icon dark left>mdi-plus</v-icon> Create
                  </v-btn>
                </template>
                <!--  <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                  v-on:click="searchDialog = true"
                >
                  <v-icon dark>mdi-filter</v-icon>
                </v-btn> -->
                <v-btn
                  depressed
                  v-if="!isEngineerChannel()"
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button btn-tab-hide"
                  color="cyan white--text"
                  v-on:click="
                    $router.push(
                      getDefaultRoute('profile.setting', {
                        query: {
                          tab: 'customer',
                        },
                      })
                    )
                  "
                >
                  <v-icon dark>mdi-cog</v-icon>
                </v-btn>
                <!-- <v-menu content-class="custom-menu-list" offset-y left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-on:click="exportCustomer"
                    >
                      <v-icon dark left>mdi-database-export</v-icon> Export
                    </v-btn>
                    <v-btn
                      :disabled="dataLoading || exportLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                      :loading="exportLoading"
                    >
                      <v-icon dark>mdi-menu</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <template v-for="(item, index) in moreActions">
                      <v-list-item
                        :key="index"
                        link
                        v-on:click="moreAction(item.action, item.sort)"
                      >
                        <v-list-item-title>
                          <v-icon v-if="item.icon" left color="cyan">{{
                            item.icon
                          }}</v-icon>
                          {{ item.title }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu> -->
                <v-menu
                  max-height="400"
                  max-width="250"
                  offset-y
                  left
                  :close-on-content-click="false"
                  content-class="white-background"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button btn-tab-hide"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>mdi-table-edit</v-icon>
                    </v-btn>
                  </template>
                  <draggable
                    tag="ul"
                    v-model="defaultColDefs"
                    v-on:change="updateVisible()"
                    class="draggable-group"
                    handle=".draggable-drag-icon"
                  >
                    <template v-for="cols in defaultColDefs">
                      <li
                        class="draggable-group-item"
                        :key="cols.field"
                        v-if="!cols.checkbox || Number(cols.checkbox) == 0"
                      >
                        <v-checkbox
                          dense
                          v-model="defaultColShow"
                          v-bind:value="cols.field"
                          :label="cols.headerName"
                          :disabled="Number(cols.fixed) || dataLoading"
                          color="cyan"
                          hide-details
                          class="mt-0 mb-0"
                          v-on:change="updateVisible()"
                        ></v-checkbox>
                        <v-icon class="draggable-drag-icon" right color="cyan"
                          >mdi-drag</v-icon
                        >
                      </li>
                    </template>
                  </draggable>
                </v-menu>
                <!-- <v-menu v-if="false" offset-y left>
                  <template v-slot:activator="{ on: menu, attrs }">
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on: tooltip }">
                        <v-btn
                          :disabled="dataLoading"
                          class="ml-2 custom-bold-button"
                          color="cyan white--text"
                          v-bind="attrs"
                          v-on="{ ...tooltip, ...menu }"
                        >
                          {{ entity.name }}
                        </v-btn>
                      </template>
                      <span>Entity</span>
                    </v-tooltip>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in entityList"
                      :key="index"
                      link
                      v-on:click="updateEntity(item)"
                    >
                      <v-list-item-title class="font-weight-600">{{
                        item.name
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu> -->
                <PageTips v-if="false" module="customer"></PageTips>
              </v-flex>
            </template>
          </template>
        </ListingHeader>
        <ListingSearchCriteria
          v-if="searchEnabled"
          pageModule="Warranty"
          :basicSearchFields="['unique_id']"
          :allowedSearchFields="searchableArray"
        ></ListingSearchCriteria>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 90vh; position: relative"
        >
          <!-- <pre>{{defaultColDefs}}</pre> -->
          <ListingTable
            :columnCount="columnLength()"
            :dataLoading="dataLoading"
            :rowData="rowData"
          >
            <template v-slot:thead>
              <thead>
                <tr>
                  <template v-for="cols in customThead">
                    <th
                      v-if="cols.visible"
                      :key="cols.id"
                      class="simple-table-th"
                      :class="{
                        'checkbox-width-limit': cols.checkbox,
                        'pointer-events-none': lodash.isEmpty(rowData),
                      }"
                    >
                      <template
                        v-if="cols.checkbox && Number(cols.checkbox) == 1"
                      >
                        <v-checkbox
                          dense
                          :disabled="dataLoading"
                          v-model="selectedAll"
                          color="cyan"
                          class="hide-margin"
                          hide-details
                        ></v-checkbox>
                      </template>
                      <template v-else>
                        <div
                          v-if="cols.sortable"
                          class="simple-table-text sortable"
                          v-on:click="
                            !dataLoading
                              ? updateSorting({
                                  field: cols.field,
                                  sort: cols.sort,
                                })
                              : 'javascript:void(0)'
                          "
                        >
                          {{ cols.headerName }}
                        </div>
                        <div v-else class="simple-table-text">
                          {{ cols.headerName }}
                        </div>

                        <template v-if="cols.sort">
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'asc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-ascending</v-icon
                          >
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'desc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-descending</v-icon
                          >
                        </template>
                      </template>
                    </th>
                  </template>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <tbody class="custom-border-bottom">
                <template v-if="!lodash.isEmpty(rowData)">
                  <tr
                    v-for="(data, index) in rowData"
                    :key="index"
                    link
                    class="warranty-listing-row"
                  >
                    <template v-for="cols in customThead">
                      <td
                        v-if="cols.visible"
                        :key="cols.id"
                        :class="{
                          'simple-table-td': !cols.checkbox,
                          'checkbox-width-limit': cols.checkbox,
                        }"
                        v-on:click="
                          Number(cols.checkbox) == 0
                            ? rowClicked(data)
                            : 'javascript:void(0)'
                        "
                      >
                        <template
                          v-if="cols.checkbox && Number(cols.checkbox) == 1"
                        >
                          <v-checkbox
                            dense
                            v-model="selectedRows"
                            v-bind:value="data.id"
                            color="cyan"
                            class="hide-margin"
                            hide-details
                          ></v-checkbox>
                        </template>
                        <template v-else-if="cols.field == 'action'">
                          <template v-if="data.status != 4 && data.status != 1">
                            <!-- <v-tooltip top content-class="custom-top-tooltip">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  depressed
                                  fab
                                  dark
                                  x-small
                                  color="green"
                                  v-bind="attrs"
                                  style="border-radius: 18px !important"
                                  v-on:click.stop.prevent="
                                    addWarrantyDialog = true
                                  "
                                  v-on="on"
                                  class="mr-3"
                                >
                                  <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                              </template>
                              <span>Edit</span>
                            </v-tooltip> -->
                            <!--  <v-tooltip top content-class="custom-top-tooltip">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  depressed
                                  fab
                                  x-small
                                  dark
                                  color="red"
                                  style="border-radius: 18px !important;"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>
                              </template>
                              <span>Delete</span>
                            </v-tooltip> -->
                            <v-tooltip
                              top
                              content-class="custom-top-tooltip"
                              v-if="!data.is_extended"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  depressed
                                  fab
                                  dark
                                  x-small
                                  color="orange"
                                  style="border-radius: 18px !important"
                                  v-bind="attrs"
                                  class="ml-3"
                                  v-on:click.stop.prevent="
                                    extentWarranty(data.id)
                                  "
                                  v-on="on"
                                >
                                  <v-icon>mdi-share-outline mdi-flip-r</v-icon>
                                </v-btn>
                              </template>
                              <span>Extend Warranty</span>
                            </v-tooltip>
                          </template>
                        </template>
                        <template v-else-if="cols.field == 'customer'">
                          <template
                            v-if="
                              data &&
                              data.equipment &&
                              data.equipment.main_type == 'main_customer'
                            "
                          >
                            <div class="warranty-listing-product">
                              <p class="m-0 custom-nowrap-ellipsis">
                                <b>Display Name: </b>
                                <template
                                  v-if="
                                    data.customer && data.customer.display_name
                                  "
                                  >{{ data.customer.display_name }}</template
                                >
                                <template v-else
                                  ><em class="text--secondary"
                                    >No Display Name</em
                                  ></template
                                >
                              </p>
                              <p class="m-0 custom-nowrap-ellipsis">
                                <b>Company: </b>
                                <template
                                  v-if="
                                    data.customer && data.customer.company_name
                                  "
                                  >{{ data.customer.company_name }}</template
                                >
                                <template v-else
                                  ><em class="text--secondary"
                                    >No Company</em
                                  ></template
                                >
                              </p>
                              <p class="m-0 custom-nowrap-ellipsis">
                                <b>Email: </b>
                                <template
                                  v-if="
                                    data.customer && data.customer.company_email
                                  "
                                  >{{ data.customer.company_email }}</template
                                >
                                <template v-else
                                  ><em class="text--secondary"
                                    >No Email</em
                                  ></template
                                >
                              </p>
                              <p class="m-0 custom-nowrap-ellipsis">
                                <b>Phone: </b>
                                <template
                                  v-if="
                                    data.customer &&
                                    data.customer.company_number
                                  "
                                  >{{ data.customer.company_number }}</template
                                >
                                <template v-else
                                  ><em class="text--secondary"
                                    >No Phone</em
                                  ></template
                                >
                              </p>
                            </div>
                          </template>
                          <template v-else>
                            <v-chip
                              label
                              color="#0d47a1"
                              outlined
                              small
                              class="p-3 mr-5"
                            >
                              Own Equipment
                            </v-chip>
                          </template>
                        </template>

                        <template v-else-if="cols.field == 'service_id'">
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Type: </b>
                            <v-chip
                              label
                              :color="
                                data &&
                                data.equipment &&
                                data.equipment.main_type == 'main_customer'
                                  ? '#4CAF50'
                                  : '#0D47A1'
                              "
                              class="text-white p-3 mr-5 mb-1"
                              small
                            >
                              <template
                                v-if="
                                  data &&
                                  data.equipment &&
                                  data.equipment.main_type == 'main_customer'
                                "
                              >
                                Customer
                              </template>
                              <template v-else> Company </template>
                            </v-chip>
                          </p>
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Name: </b>
                            <template v-if="data && data.equipment">{{
                              data.equipment.name
                            }}</template>
                            <template v-else
                              ><em class="text--secondary"
                                >No Name</em
                              ></template
                            >
                          </p>
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Site Location: </b>
                            <template
                              v-if="
                                data &&
                                data.equipment &&
                                data.equipment.property &&
                                data.equipment.property.property_address
                              "
                            >
                              {{ data.equipment.property.street_1 }},
                              <br
                                v-if="
                                  data.equipment.property.street_2 ||
                                  data.equipment.property.unit_no
                                "
                              />
                              <template v-if="data.equipment.property.street_2">
                                {{ data.equipment.property.street_2 }},
                              </template>
                              <template v-if="data.equipment.property.unit_no">
                                {{ data.equipment.property.unit_no }},
                              </template>
                              <br />
                              {{ data.equipment.property.country }},
                              {{ data.equipment.property.zip_code }},
                            </template>
                            <template v-else
                              ><em class="text--secondary"
                                >No Site Location</em
                              ></template
                            >
                          </p>
                        </template>
                        <template v-else-if="cols.field == 'barcode'">
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Serial No: </b>
                            <Barcode
                              class="ml-1"
                              small
                              :barcode="data.unique_id"
                            ></Barcode>
                          </p>
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Cost : </b>
                            <template v-if="data && data.cost">
                              <v-chip
                                label
                                small
                                class="font-weight-600 custom-grey-border text-uppercase ml-9"
                                text-color="white"
                                color="blue"
                              >
                                {{ formatMoney(data.cost) }}
                              </v-chip>
                            </template>
                            <template v-else
                              ><em class="text--secondary"
                                >No cost</em
                              ></template
                            >
                          </p>
                        </template>
                        <!--  <template v-else-if="cols.field == 'unique_id'">
                          <v-chip
                            label
                            small
                            class="font-weight-600 custom-grey-border text-uppercase"
                            text-color="white"
                            color="cyan"
                          >
                            {{ data.unique_id }}
                          </v-chip>
                        </template> -->

                        <template v-else-if="cols.field == 'status'">
                          <div class="project-listing-status">
                            <CustomStatus
                              small
                              :status="data.status"
                              endpoint="warranty/status"
                            ></CustomStatus>
                          </div>
                          <v-chip
                            v-if="
                              data.extended_id &&
                              data.is_extended &&
                              data.extended
                            "
                            label
                            small
                            outlined
                            class="font-weight-600 custom-grey-border text-uppercase ml-2"
                            text-color=""
                            color="red"
                          >
                            {{ data.extended.unique_id }}
                          </v-chip>
                        </template>
                        <template v-else-if="cols.field == 'start_date'">
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Start Date: </b>{{ formatDate(data.start_date) }}
                          </p>
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>End End: </b>{{ formatDate(data.end_date) }}
                          </p>
                          <p class="m-0 custom-nowrap-ellipsis">
                            <template v-if="data.end_date">
                              <b
                                >Expiring on
                                {{ formatDate(data.end_date) }}
                                midnight.</b
                              >
                            </template>
                          </p>
                          <p class="m-0 custom-nowrap-ellipsis">
                            <template v-if="data.end_date">
                              <b>Remaining :</b>
                              {{ remaningDate(data) }}
                              <span style="color: red; font-weight: 700">
                                ({{ totalDays(data) }} Days)</span
                              >
                            </template>
                          </p>
                        </template>
                        <template v-else-if="cols.field == 'added_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.added_by)"
                            :data="data"
                          >
                            <template v-slot:display_name>
                              {{ data.added_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              {{ data.created_at }}
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDate(data.added_at) }}
                              {{ formatDateSartTime(data.added_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else-if="cols.field == 'updated_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.updated_by)"
                            :data="data"
                          >
                            <template v-slot:display_name>
                              {{ data.updated_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              {{ data.modified_at }}
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDateTime(data.updated_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else>
                          <div v-html="printRowData(cols, data)"></div>
                        </template>
                      </td>
                    </template>
                  </tr>
                </template>
                <tr v-else-if="!dataLoading">
                  <td :colspan="8" class="py-4">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no warrnaty at the moment.
                    </p>
                  </td>
                </tr>
              </tbody>
            </template>
          </ListingTable>
        </perfect-scrollbar>

        <ListingFooter
          :dataLoading="dataLoading"
          :showingFrom="showingFrom"
          :showingTo="showingTo"
          :totalRows="totalRows"
          :currentPage="currentPage"
          :totalPages="totalPages"
        ></ListingFooter>
        <!--    <Dialog :commonDialog="searchDialog">
          <template v-slot:title>Filter Customer</template>
          <template v-slot:body>
            <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll custom-box-top-inner-shadow"
              style="max-height: 90vh; position: relative"
            >
              <v-container fluid class="pt-0 custom-search-filter">
                <v-row>
                  <template v-for="(field, index) in searchableArray">
                    <v-col md="6" :key="index" v-if="field != 'dates'">
                      <v-text-field
                        v-model.trim="listingSearch[field]"
                        dense
                        filled
                        hide-details
                        :label="
                          field == 'customer_detail_phone[value]'
                            ? 'Company Phone'
                            : field == 'customer_detail[value]'
                            ? 'Company Email'
                            : getFilterLabel(field, 'Customer')
                        "
                        solo
                        flat
                        clearable
                        @keydown.enter="filterRows"
                        color="cyan"
                      ></v-text-field>
                    </v-col>
                  </template>
                </v-row>
              </v-container>
            </perfect-scrollbar>
          </template>
          <template v-slot:action>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              color="cyan white--text"
              v-on:click="filterRows"
            >
              Filter
            </v-btn>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="searchDialog = false"
            >
              Close
            </v-btn>
          </template>
        </Dialog> -->
        <ImportTemplate
          :dialog="importDialog"
          sample-url="customer/sample-download"
          api-url="customer/import"
          title="Import Customer"
          v-on:close="importDialog = false"
        ></ImportTemplate>
        <template v-if="addWarrantyDialog && getPermission('warranty:create')">
          <ManageWarranty
            :allCustomerList="allCustomerList"
            :allEquipemtList="allEquipemtList"
            :dialog-status="addWarrantyDialog"
            v-on:close:dialog="addWarrantyDialog = false"
            v-on:update:warranty="getRows"
          ></ManageWarranty>
        </template>
      </template>
    </ListingTemplate>
    <ExtentWarranty
      v-if="extentWarrantyDialog"
      :allCustomerList="allCustomerList"
      :allEquipemtList="allEquipemtList"
      :warrantyDetail="warrantyArr"
      :dialogStatus="extentWarrantyDialog"
      v-on:close:dialog="extentWarrantyDialog = false"
      v-on:update:warranty="getRows"
    ></ExtentWarranty>
  </div>
  <!--end::Customer Listing-->
</template>

<script>
import draggable from "vuedraggable";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  PATCH,
  DOWNLOAD,
  POST,
  GET,
} from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ExtentWarranty from "@/view/pages/warranty/Extended-Warranty.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
/* import Dialog from "@/view/pages/partials/Dialog.vue"; */
import PageTips from "@/view/layout/extras/offcanvas/PageTips.vue";
import PageHeaderCount from "@/view/pages/partials/PageHeaderCount.vue";
import ImportTemplate from "@/view/pages/partials/Import-Template.vue";
import JwtService from "@/core/services/jwt.service";
import { saveAs } from "file-saver";
import moment from "moment";
import ManageWarranty from "@/view/pages/partials/Create-Or-Update-Warranty.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
// import TextInput from "@/view/pages/TextInput";
import DateRangePicker from "@/view/pages/partials/DateRangePicker";

export default {
  mixins: [CommonMixin, ListingMixin],
  name: "warranty-list",
  data() {
    return {
      pageTips: false,
      exportLoading: false,
      pageModule: "warranty-listing",
      routeAPI: "warranty",
      momentObject: moment,
      routeName: "warranty",
      routeDetailName: "warranty.detail",
      allEquipemtList: [],
      status: "all",
      addWarrantyDialog: false,
      allCustomerList: [],
      file: "",
      search: null,
      serial_no: null,
      status_type: null,
      date_range: [],
      warrantyArr: {},
      importDialog: false,
      extentWarrantyDialog: false,
      statusList: [],
      customThead: [],
      moreActions: [
        {
          title: "Import Customer(s)",
          action: "import_items",
          divider: true,
          icon: "mdi-database-import",
        },

        /*
        {
          title: "Export Customer(s)",
          action: "export_items",
          divider: false,
          icon: "mdi-database-export",
        },*/
        /*{
          title: "Export Current View",
          action: "export_current_view",
          divider: true,
          icon: "mdi-database-export",
        },*/
        {
          title: "Refresh List",
          action: "refresh_list",
          divider: true,
          icon: "mdi-refresh",
        },
      ],
      customerType: [
        {
          text: "Company",
          value: "company",
          color: "blue accent-4",
        },
        {
          text: "Individual",
          value: "personal",
          color: "red accent-4",
        },
      ],
      customerMoreAction: [
        {
          title: "Mark as Active",
          icon: "mdi-check-all",
          action: "active",
        },
        {
          title: "Mark as In-Active",
          icon: "mdi-check-all",
          action: "inactive",
        },
      ],
    };
  },
  components: {
    draggable,
    PageTips,
    ImportTemplate,
    PageHeaderCount,
    Barcode,
    TableActivity,
    CustomStatus,
    ManageWarranty,
    ListingTemplate,
    ListingFooter,
    ListingTable,
    ListingHeader,
    ListingSearchCriteria,
    ExtentWarranty,
    DateRangePicker,
    // TextInput
    /* Dialog, */
  },
  methods: {
    remaningDate(date) {
      // var a = moment(date.end_date);
      // var b = moment(date.start_date);

      // var years = a.diff(b, "year");
      // b.add(years, "years");

      // var months = a.diff(b, "months");
      // b.add(months, "months");

      // var days = a.diff(b, "days");
      // if(days == 0){
      //   days = '';
      // }else{
      //   days = days + " Days"
      // }
      // if(months == 0){
      //   months = '';
      // }else{
      //   months = months + " Months"
      // }
      // if(years == 0){
      //   years = '';
      // }else{
      //   years = years + " Years"
      // }
      // return years + months + days;

      var a = moment(date.end_date);
      //var b = moment(date.start_date);
      //console.log(b);
      var b = moment().format("YYYY-MM-DD");
      b = moment(b);
      var years = a.diff(b, "year");
      b.add(years, "years");

      var months = a.diff(b, "months");
      b.add(months, "months");

      var days = a.diff(b, "days");
      if (days <= 0) {
        days = "";
      } else {
        days = days + " Days";
      }
      if (months <= 0) {
        months = "";
      } else {
        months = months + " Months ";
      }
      if (years <= 0) {
        years = "";
      } else {
        years = years + " Years ";
      }
      return years + months + days;
    },
    totalDays(date) {
      // var a =  moment(date.end_date);
      // var b =  moment(date.start_date);
      // var final =   a.diff(b, 'days')   // =1
      // return final;
      let today = moment().format("YYYY-MM-DD");

      var a = moment(date.end_date);
      var b = moment(today); //date.start_date
      var final = a.diff(b, "days"); // =1
      if (final <= 0) {
        final = 0;
      }
      return final;
    },
    formatDateSartTime(row) {
      return moment(row).format("hh:mm A");
    },
    extentWarranty(id) {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: "warranty/" + id,
        })
        .then(({ data }) => {
          _this.warrantyArr = data;
          if (_this.warrantyArr) {
            this.extentWarrantyDialog = true;
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getAllCustomers() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "customer-list" })
        .then(({ data }) => {
          _this.allCustomerList = data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getProductType(type) {
      let result = null;
      switch (type) {
        case "goods":
          result = "Product";
          break;
        case "service":
          result = "Service";
          break;
        case "equipment":
          result = "Equipment";
          break;
      }
      return result;
    },
    routeToDetail(row) {
      if (row) {
        if (row.product_type === "goods") {
          this.$router.push(
            this.getDefaultRoute("product.detail", {
              params: {
                id: row.product_id,
              },
            })
          );
        } else if (row.product_type === "equipment") {
          this.$router.push(
            this.getDefaultRoute("equipment.detail", {
              params: {
                id: row.product_id,
              },
            })
          );
        }
      }
    },
    moreAction(action) {
      const _this = this;
      switch (action) {
        case "import_items":
          _this.importDialog = true;
          break;
        case "export_items":
          _this.bulkCustomerExport();
          break;
        case "export_current_view":
          _this.exportCurrentView("customer");
          break;
        case "refresh_list":
          _this.getRows();
          break;
      }
    },
    exportCustomer() {
      //console.log(row,"rowhhh");
      //console.log(this.searchableArray,"searchableArray");

      let downloadURL = process.env.VUE_APP_API_URL + "customer/export";
      downloadURL = new URL(downloadURL);
      const token = JwtService.getToken();
      downloadURL.searchParams.append("token", token);
      //downloadURL.searchParams.append("search", this.queryParams.search);
      this.lodash.map(this.queryParams, function (row, index) {
        downloadURL.searchParams.append(index, row);
      });

      this.lodash.map(this.listingSearch, function (row, index) {
        // console.log(index, "indexdd")

        if (index == "dates") {
          if (row && row[0]) {
            downloadURL.searchParams.append("dates[0]", row[0]);
          }
          if (row && row[1]) {
            downloadURL.searchParams.append("dates[1]", row[1]);
          }
        } else {
          downloadURL.searchParams.append(index, row);
        }

        //  downloadURL.searchParams.append(index, row);
      });
      window.open(downloadURL, "_blank");
    },
    updateMoreAction(param) {
      const _this = this;
      switch (param) {
        case "active":
          _this.bulkCustomerUpdate({
            customers: _this.selectedRows,
            status: 1,
          });
          break;
        case "inactive":
          _this.bulkCustomerUpdate({
            customers: _this.selectedRows,
            status: 0,
          });
          break;
      }
    },

    importRows() {
      // const _this = this;
      const file = this.$refs.file.files[0];
      if (!file) {
        // alert("No file chosen");
        return;
      }
      const data = new FormData();
      data.append("excel", file);
      const _this = this;
      _this.$store
        .dispatch(POST, {
          url: "customer/import",
          data: data,
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.selectedRows = [];
          _this.getRows();
          _this.importDialog = false;
        });
    },
    bulkCustomerUpdate(requestObject) {
      const _this = this;
      _this.$store
        .dispatch(PATCH, {
          url: "customer",
          data: requestObject,
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.selectedRows = [];
          _this.getRows();
        });
    },
    bulkCustomerExport() {
      const _this = this;
      let fileName = new Date();
      _this.exportLoading = true;
      _this.$store
        .dispatch(DOWNLOAD, {
          url: "customer/export",
        })
        .then(({ data }) => {
          saveAs(data, "customer-export-" + fileName.getTime() + ".xlsx");
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.exportLoading = false;
        });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Warranty",
      },
    ]);
    this.getRows();
    // alert(this.defaultColShow.length)
  },
  beforeMount() {
    const _this = this;
    _this.lodash.assign(_this.queryParams, _this.$route.query);

    /* _this.defaultColDefs = [
      {
        headerName: "",
        field: "id",
        sort: null,
        visible: true,
        fixed: true,
        sortable: false,
        checkbox: true,
        order: 1,
      },
      {
        headerName: "Active / In-Active",
        field: "activated",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 2,
      },
      {
        headerName: "Image",
        field: "image",
        sort: null,
        image: true,
        visible: true,
        fixed: false,
        sortable: false,
        order: 3,
      },
      {
        headerName: "Customer #",
        field: "barcode",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 4,
      },
      {
        headerName: "Customer Info",
        field: "display_name",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 5,
      },
      {
        headerName: "Contact Person",
        field: "contact_person",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 6,
      },
      {
        headerName: "Company Address",
        field: "billing_address",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 7,
      },
      {
        headerName: "Total",
        field: "total",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 8,
      },
      {
        headerName: "Created Time",
        child: "created_at",
        field: "added_at",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 9,
      },
      {
        headerName: "Last Modified Time",
        child: "modified_at",
        field: "updated_at",
        sort: null,
        visible: false,
        fixed: false,
        sortable: false,
        order: 10,
      },
    ]; */

    /* const defaultColDefs = _this.lodash.filter(_this.defaultColDefs, {
      visible: true,
    });

    _this.defaultColShow = defaultColDefs.map((col) => col.field); */

    _this.status = _this.$route.query.status || _this.status;

    // _this.applyColState();

    _this.paginationPageSize =
      window.localStorage.getItem(_this.pageModule) || 50;
  },
};
</script>
